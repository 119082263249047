@keyframes disco {
	from {
		transform: translateY(-50%) rotate(0deg);
	}

	to {
		transform: translateY(-50%) rotate(360deg);
	}
}

.button {
	/* Customizable */
	--button-color-bg: rgb(24 24 27);
	--button-color-border: rgba(255 255 255 0);
	--button-color-disco: rgb(48, 220, 255);
	--button-color-text: rgb(161 161 170);
	--button-color-text-busy: rgb(113 113 122);
	--button-border-width: 5px;
	--button-bg-gradient-start: rgba(1 1 1 0.9);
	--button-bg-gradient-end: rgb(20 20 20);
	--button-disco-animation: disco 2.5s linear infinite;
	--button-disco-gradient-stops: #f9dcdd 0deg, #c1a4e8 60deg, rgba(0, 0, 0, 0) 100deg, rgba(0, 0, 0, 0) 130deg,
		rgba(0, 0, 0, 0) 160deg, #f2efe8 190deg, #b8e2fb 230deg, rgba(0, 0, 0, 0) 270deg;

	--button-disco-opacity: 1;
	--button-radius: 0.5rem;
	--button-transition-duration: 5s;
	--button-transition-easing: cubic-bezier(0.4, 0, 0.2, 1);
	--button-transition: var(--button-transition-duration) var(--button-transition-easing);
	/**
   * Using an advance mathematic technique often referred to as
   * "winging it", we'll offset the aspect ratio by a figure
   * that brings the shape of the "disco" element as close to
   * the edge of the button as possible – to reduce the effect
   * of "easing".
   */
	--button-aspect-ratio-multiplier: 4;
	/* Styles */
	appearance: none;
	position: relative;
	border: 0;
	font: inherit;
	/* width: max-content; */
	overflow: hidden;
	margin: calc(var(--button-border-width) * 0);
	padding: var(--button-border-width);
	color: var(--button-color-text);
	border-radius: var(--button-radius);
	transform: none;
	user-select: none;
	will-change: transform;
	background-color: var(--button-color-border);
	/**
   * This one's optional, it just stops the button from jumping around when the
   * content width changes
   */
	min-width: 8rem;
}

.button:hover {
	filter: brightness(0.95);
}

.button:active {
	transform: scale(0.95);
}

@media (prefers-reduced-motion: no-preference) {
	.button {
		transition: var(--button-transition);
		transition-property: color, filter, transform;
	}
}

.content {
	display: inline-flex;
	position: relative;
	border-radius: calc(var(--button-radius) - var(--button-border-width));
	background-image: linear-gradient(
		to top right,
		var(--button-bg-gradient-start),
		var(--button-bg-gradient-end)
	);
	background-color: var(--button-bg-gradient-end);
	width: 100%;
	justify-content: center;
	padding: 0.625rem 1rem;
	text-align: center;
	z-index: 10;
	@apply rounded-xl;
}

.content.hidden {
	background-image: linear-gradient(to top right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));

	background-color: rgba(20, 20, 20);
}

.disco,
.disco::before {
	position: absolute;
	width: 100%;
}

.disco {
	inset: 0;
	height: 100%;
	top: 50%;
	/* transform: translateY(-50%) scaleX(calc(var(--button-aspect-ratio) * var(--button-aspect-ratio-multiplier))); */
	transform: translateY(-50%) scaleY(calc(var(--button-aspect-ratio-multiplier)))
		scaleX(calc(var(--button-aspect-ratio-multiplier)));
	will-change: transform;
}

.disco::before {
	content: "";
	position: absolute;
	left: 0;
	min-height: 100%;
	top: 50%;
	aspect-ratio: 1/1;
	transform-origin: center;
	background-image: conic-gradient(var(--button-disco-gradient-stops));
	opacity: var(--button-disco-opacity-enabled, 0);
	/**
   * This one's not strictly necessary, but I think it helps soften the gradient
   * and reduces animation "easing".
   */
	filter: blur(8px);
}

@media (prefers-reduced-motion: reduce) {
	.disco::before {
		transform: translateY(-50%) rotate(0deg);
	}
}

@media (prefers-reduced-motion: no-preference) {
	.disco::before {
		animation: var(--button-disco-animation);
		animation-play-state: var(--button-disco-animation-state, paused);
		transition: var(--button-transition);
		transition-property: opacity;
	}
}

.button.isBusy {
	--button-color-text: var(--button-color-text-busy);
	--button-disco-animation-state: running;
	--button-disco-opacity-enabled: var(--button-disco-opacity);
	cursor: progress;
}
